import React from 'react';
import {graphql} from "gatsby"
import Vision from '../../../components//UkResidents/HCP/ourVision';
import ResidentsFooter from '../../../components/residentsFooter';
import Header from '../../../components/UkResidents/HCP/header';
import ResidentsHcpBio from '../../../components/UkResidents/HCP/residentsHcpProfessionalsBio';
import UkResidentsBanner from '../../../components/UkResidents/HCP/ukResidentsBanner';
import queryString from 'query-string';
import SEO from "../../../components/seo"
import { ValidateRoute } from '../../../util/route-helper';
import TemplateWrapper from "../../../components/TemplateWrapper"
import Footer from '../../../components/UkResidents/HCP/footer';
export const pageQueryHomeTemplateUkHcp = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulHomeTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
                spaceId
                slug
                bannerSection {
                    json
                }
                missionSection {
                    json
                }
                topExcerptText {
                    json
                }
                topExcerptButtonUrl
                topExcerptImage {
                    file {
                      url
                    }
                }
                bottomExcerptImage {
                    file {
                      url
                    }
                }
                bottomExcerptText {
                    json
                }
                bottomExcerptButtonUrl
        }
    } 
`

const HomeTemplatePageUkHcp = (props) => {
    const queryData = props.data.contentfulHomeTemplate;

    let [animate, setAnimate] = React.useState(false);

    React.useEffect(() => {
        
        let {veeva} = queryString.parse(props.location.search || "");
  
        if(!veeva){
          setAnimate(true);
        }
  
    }, []);
  
    return ( 
      <TemplateWrapper>
          <div className={animate ? "" : "overwrite_animations"}>
        <div>
            <SEO title="Home | UK Residents | HCP" />
            <Header/>
            <UkResidentsBanner queryData={queryData} animate={animate} />
            <ResidentsHcpBio queryData={queryData} animate={animate} />
            <Vision queryData={queryData} animate={animate} />
            <Footer queryData={queryData} animate={animate} />
        </div>
        </div>
      </TemplateWrapper>
    )
}

export default HomeTemplatePageUkHcp